<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
    </legend>
    <div class="row">
      <div class="col-md-3">
        <fieldset class="ml-1 mt-4 px-1">
          <legend>
            <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_classification_section_1'])"
               @click="openSectionModal(
                 sectionSelectedData.column3,
                 'ar_home_page_classification_section_1_column_3',
                 [{name :'category'},{name:'classification'},{name:'poll'}]
               )"
               class="fas fa-pen visual-editor-btn"></i>
          </legend>
          <div class="section-header">
            <label v-if="sectionSelectedData.column3.type == 'category'">{{ sectionSelectedData.column3.category ? sectionSelectedData.column3.category.name : '' }}</label>
            <label v-if="sectionSelectedData.column3.type == 'classification'">{{ sectionSelectedData.column3.classification ? sectionSelectedData.column3.classification.name : '' }}</label>
            <label v-if="sectionSelectedData.column3.type == 'poll'">{{ sectionSelectedData.column3.poll ? 'تصويت' : '' }}</label>
            <a href="">more</a>
          </div>
          <div class="section-content">
            <div class="news-card my-2 d-flex justify-content-end  " v-for="news in sectionSelectedData.column3.news">
              <div class="text-right pr-1">
                <span class="px-2 py-1  classification-column3-title">{{ news.title }}</span>
              </div>
              <img class="" :src="getUrl()+news.image" alt="no Image Found" style="width:40%; height:40%">
            </div>
            <div v-if="sectionSelectedData.column3.type == 'poll'">
              <p>{{sectionSelectedData.column3.poll.question}}</p>
              <div v-for="(item, x) in sectionSelectedData.column3.poll.answers" :key="'Item_'+x">
                <card class="mt-2 mb-0 text-right">
                  <span>{{ item.text }}</span>
                  <span class="badge badge-success mx-2">{{ x+1 }}</span>
                </card>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="ml-1">
          <legend>
            <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_classification_section_1'])"
               @click="openSectionModal(
                 sectionSelectedData.column2,
                 'ar_home_page_classification_section_1_column_2',
                 [{name :'category'},{name:'classification'}]
                 )"
               class="fas fa-pen visual-editor-btn"></i>
          </legend>
          <div class="section-header">
            <label v-if="sectionSelectedData.column2.type == 'category'">{{ sectionSelectedData.column2.category ? sectionSelectedData.column2.category.name : '' }}</label>
            <label v-if="sectionSelectedData.column2.type == 'classification'">{{ sectionSelectedData.column2.classification ? sectionSelectedData.column2.classification.name : '' }}</label>
            <label v-if="sectionSelectedData.column2.type == 'poll'">{{ sectionSelectedData.column2.poll ? sectionSelectedData.column2.poll.question : '' }}</label>
            <a href="">more</a>
          </div>
          <div class="section-content">
            <div class="news-card my-2" v-for="news in sectionSelectedData.column2.news">
              <img :src="getUrl()+news.image" alt="no Image Found" style="width:100%; max-height: 300px">
              <div class="d-flex align-items-end flex-column " >
                <div class="p-2 font-weight-bold text-right">{{ news.title }}</div>
                <div class="p-2 text-right">{{ news.excerpt }}</div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-3">
        <fieldset class="mr-1 mt-4">
          <legend>
            <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_classification_section_1'])"
               @click="openSectionModal(
                 sectionSelectedData.column1,
                 'ar_home_page_classification_section_1_column_1',
                 [{name :'category'},{name:'classification'},{name:'poll'}]
                 )"
               class="fas fa-pen visual-editor-btn"></i>
          </legend>
          <div class="section-header">
            <label v-if="sectionSelectedData.column1.type == 'category'">{{ sectionSelectedData.column1.category ? sectionSelectedData.column1.category.name : '' }}</label>
            <label v-if="sectionSelectedData.column1.type == 'classification'">{{ sectionSelectedData.column1.classification ? sectionSelectedData.column1.classification.name : '' }}</label>
            <label v-if="sectionSelectedData.column1.type == 'poll'">{{ sectionSelectedData.column1.poll ? 'تصويت' : '' }}</label>

            <a href="">more</a>
          </div>
          <div class="section-content">
            <div class="news-card my-2" v-for="news in sectionSelectedData.column1.news">
              <img :src="getUrl()+news.image" alt="no Image Found" style="width:100%; max-height: 300px">
              <div class="d-flex align-items-end flex-column " >
                <div class="p-2 font-weight-bold text-right">{{ news.title }}</div>
              </div>
            </div>
            <div v-if="sectionSelectedData.column1.type == 'poll'">
              <p>{{sectionSelectedData.column1.poll.question}}</p>
              <div v-for="(item, x) in sectionSelectedData.column1.poll.answers" :key="'Item_'+x">
                <card class="mt-2 mb-0 text-right">
                  <span>{{ item.text }}</span>
                  <span class="badge badge-success mx-2">{{ x+1 }}</span>
                </card>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <second-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_classification_section_1'])"
                          :visible="sectionModalVisibility"
                          :selectedData="modalSelectedData"
                          :classificationData="classificationData"
                          :categoriesData="categoriesData"
                          :columnTypeList="columnTypeList"
                          :lang="lang"
                          :dataKey="dataKey"
                          @close="closeSectionModal"
                          @saveData="saveData"
    >

    </second-section-modal>
  </fieldset>
</template>

<script>
import SecondSectionModal from "./SecondSectionModal";

export default {
  name: 'second-section',
  components: {
    SecondSectionModal,
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        if (newValue) {
          this.sectionSelectedData = newValue;
        }
      },
      deep: true
    }
  },
  props: {
    sectionData: {
      type: Object,
    },
    lang:{
      type:String,
      default:'ar'
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
  },
  mounted() {
  },
  data() {
    return {
      modalSelectedData: {},
      sectionSelectedData: {
        column1: {type:'',category: null,classification: null, news: [], poll:null},
        column2: {type:'',category: null,classification: null, news: []},
        column3: {type:'',category: null,classification: null, news: [], poll:null},
      },
      sectionModalVisibility: false,
      dataKey:null,
      columnTypeList:[],
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSectionModal(sectionData,dataKey,columnTypeList) {
      this.columnTypeList = columnTypeList;
      this.dataKey = dataKey;
      this.modalSelectedData = {...sectionData};
      this.sectionModalVisibility = true;
    },
    closeSectionModal() {
      this.sectionModalVisibility = false;
    },
    saveData(value) {
      this.$emit('saveData', value);
    }
  }

}
</script>
<style>
.section-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
}
.classification-column3-title{
  font-size: xx-small;
}
</style>
