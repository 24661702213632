<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">

    <div class="col-md-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Column Type'"
        :list="columnTypeList"
        :listItemLabel="'name'"
        :listItemValue="'name'"
        @change="classificationChanged()"
        v-model="columnType">
      </fg-select>
      <div v-if="columnType == 'classification'">
        <fg-select
          name="classification_id"
          size="large"
          filterable
          clearable
          placeholder="Classification"
          :input-classes="'select-default'"
          :label="'Classification'"
          :list="classificationList"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          @change="classificationChanged()"
          v-model="classification_id">
        </fg-select>
        <paginated-select
          :labelTitle="'Choose News'"
          @select-changed="draggableClassificationNewsChanged"
          :filterData="{key: 'classification_id', value: this.classification_id}"
          :apiUrl="'ar/news/paginatedNews'"
          :lastData="draggableNews"
          :selectLimiter="4"
          :key="paginatedSelectKey"
        >
        </paginated-select>
      </div>
      <div v-if="columnType == 'poll'">
        <paginated-select
          :labelTitle="'Choose Poll'"
          :isMultiple="false"
          :columnsName="['id', 'question']"
          :apiUrl="'polls/paginatedPolls'"
          :lastData="selectedPoll"
          :selectLimiter="1"
          :key="paginatedSelectKey"
          @select-changed="pollsChanged"
        >
        </paginated-select>
      </div>
      <div v-if="columnType == 'category'">
        <fg-select
          name="category_id"
          size="large"
          filterable
          clearable
          placeholder="Categories"
          :input-classes="'select-default'"
          :label="'Categories'"
          :list="categoryList"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          @change="categoryChanged()"
          v-model="category_id">
        </fg-select>
        <paginated-select
          :labelTitle="'Choose News'"
          @select-changed="draggableCategoryNewsChanged"
          :filterData="{key: 'category_id', value: this.category_id}"
          :apiUrl="'ar/news/paginatedNews'"
          :lastData="draggableCategoryNews"
          :selectLimiter="4"
          :key="paginatedSelectKey"
        >
        </paginated-select>
      </div>
    </div>
    <draggable v-if="columnType == 'classification'" class="container" v-model="draggableNews" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableNews" :key="'Item_'+x">
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>

      </div>
    </draggable>
    <draggable v-if="columnType == 'category'" class="container" v-model="draggableCategoryNews" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableCategoryNews" :key="'Item_'+x">
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>
      </div>
    </draggable>

    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import draggable from 'vuedraggable';
import FgSelect from "@/components/Inputs/formGroupSelect";
import PaginatedSelect from "../../../../components/paginatedSelect";

export default {
  name: 'second-section-modal',
  components: {
    PaginatedSelect,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    vSelect,
    draggable,
    FgSelect,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    selectedData: {
      type: Object,
    },
    classificationData: {
      type: Array,
    },
    categoriesData: {
      type: Array,
    },
    columnTypeList: {
      type: Array,
    },

    lang:{
      type:String,
      default:'ar'
    },
    dataKey:{
      type:String,
    }
  },
  data() {
    return {
      newsList: [],
      newsCount: 0,
      selectKey: 0,
      classificationList: [],
      classification_id: null,
      draggableNews: [],
      draggableCategoryNews: [],
      categoryList: [],
      category_id: null,
      columnType: '',
      paginatedSelectKey: 0,
      pollList: [],
      poll_id: null,
      selectedPoll: [],
    }
  },
  watch: {
    classificationData: {
      handler: function (newValue) {
        this.classificationList = newValue;
        this.paginatedSelectKey++;
      },
      deep: true
    },
    categoriesData: {
      handler: function (newValue) {
        this.categoryList = newValue;
        this.paginatedSelectKey++;
      },
      deep: true
    },
    selectedData: {
      handler: function (newValue) {
        this.setModalData();
        this.paginatedSelectKey++;
      },
      deep: true
    },
  },
  mounted() {
    this.draggableNews = this.selectedNews;
    this.draggableCategoryNews = this.selectedNews;
  },
  methods: {
    draggableCategoryNewsChanged(selectedNews){
      this.draggableCategoryNews = selectedNews
    },
    pollsChanged(value){
      this.poll_id = value.id;
    },
    draggableClassificationNewsChanged(selectedNews){
      this.draggableNews = selectedNews
    },
    classificationChanged(){
      this.draggableNews = [];
      this.draggableCategoryNews = [];
      this.paginatedSelectKey++;
    },
    categoryChanged(){
      this.draggableNews = [];
      this.draggableCategoryNews = [];
      this.paginatedSelectKey++;
    },
    closeModal() {
      this.$emit('close');
    },
    setModalData() {
      this.classification_id = null;
      this.category_id = null;
      this.poll_id = null;
      this.draggableNews = [];
      this.draggableCategoryNews = [];

      if (this.selectedData) {
        if (this.selectedData.news) {
          if(this.selectedData.type == 'category'){
            this.selectedData.news.map((element, index) => {
              this.draggableCategoryNews.push({id: element.id, title: element.title});
            })
          }
          if(this.selectedData.type == 'classification'){
            this.selectedData.news.map((element, index) => {
              this.draggableNews.push({id: element.id, title: element.title});
            })
          }
          if(this.selectedData.type == 'poll'){
            this.poll_id = this.selectedData.poll.id;
            this.selectedData.poll.title = this.selectedData.poll.question;
            this.selectedPoll.push(this.selectedData.poll);
          }
        }
        this.columnType = this.selectedData.type;
        if (this.selectedData.classification) {
          this.classification_id = this.selectedData.classification.id;
        }
        if (this.selectedData.category) {
          this.category_id = this.selectedData.category.id;
        }
      }
    },
    confirmModal() {
      let selectedData = {
        'type':this.columnType,
        'classification_id': (this.columnType == 'classification')? this.classification_id : null,
        'category_id': (this.columnType == 'category')? this.category_id : null,
        'news': [],
        'poll_id': (this.columnType == 'poll')? this.poll_id : null ,
      }

      if(this.columnType == 'category'){
        this.draggableCategoryNews.map((element, index) => {
          let order = element.sort_order = index + 1;
          selectedData.news.push({...element, sort_order: order});
        });
      }
      if(this.columnType == 'classification'){
        this.draggableNews.map((element, index) => {
          let order = element.sort_order = index + 1;
          selectedData.news.push({...element, sort_order: order});
        });
      }
      let data = {
        'key': this.dataKey,
        'value': selectedData,
        'lang': this.lang,
      }
      console.log(data);
      this.$emit('saveData', data);
      this.closeModal();
    },
    newsPaginated() {
      if (!this.classification_id && !this.category_id) {
        return;
      }
      let filterData = (this.columnType == 'category')? {key: 'category_id', value: this.category_id} : {key: 'classification_id', value: this.classification_id};
      let paginatorData = {
        offset: this.offset,
        limit: this.limit,
        search: this.search,
        filters: [filterData]
      };
      this.axios.post("ar/news/paginatedNews", paginatorData).then((response) => {
        this.newsList = response.data.news;
        this.newsCount = response.data.newsCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    moveEventFunction() {
      // this.news.map((element, index) => { element.sort_order =  index})
    },

  }

}
</script>
<style>

</style>
