import DashboardLayout from "@/pages/layouts/DashboardLayout";

let authorRoutes = {
  path: '/authors',
  component: DashboardLayout,
  redirect: '/authors/list',
  children: [
    {
      path: 'list/ar',
      name: 'Authors List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/authors/AuthorList'),
      meta: {
        permissionsCodes: ['ar/authors/index', 'restricted_own/ar/authors/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Author',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/authors/AuthorForm'),
      meta: {
        permissionsCodes: ['ar/authors/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Author',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/authors/AuthorForm'),
      meta: {
        permissionsCodes: ['ar/authors/update', 'restricted_own/ar/authors/update'],
      }
    }
  ]
}

export default authorRoutes;
