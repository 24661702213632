<template>
    <activity-logs-table
      :apiUrl="'logs/user-actions'"
      :advanceSearchFilter="advanceSearchFilter"
      :title="'User Actions'"
      :dataParam="dataParam"
    ></activity-logs-table>
</template>
<script>
import ActivityLogsTable from "@/pages/activityLogs/ActivityLogsTable"

export default {
  components: {
    ActivityLogsTable
  },

  data() {

    return {
      advanceSearchFilter: [
        {
          key: 'activity_logs.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'activity_logs.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'activity_logs.description',
          type: 'multiple-select',
          frontType:'select',
          strategy: '',
          url:'logs/types',
          label: 'type',
          class: 'col-6',
          multiple: true ,
          value: null,
        },
      ],
      dataParam: {"causer_id":this.$route.params['id']},
    }
  },

  mounted() {
  },

  methods: {
  },

}
</script>
