<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Live News List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/create'])"
                        :to="'/live-news/create/'+lang"
                        class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :advanceSearchFilter="advanceSearchFilter"
            :row-key="'id'"
            :api-url="lang+'/live-news/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="publish">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/publish-twitter']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/publish-twitter', scope.row.creator_id)"
                   v-tooltip.top-center="'Publish on twitter'"
                   class="btn-success btn-simple btn-link"
                   style="color:#1d9bf0;font-size: 24px;cursor: pointer"
                   @click="openPublishModal(scope.row.id, 'twitter')"><i class="fab fa-twitter"></i></a>


              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/live-news/edit/'+scope.row.id+'/'+lang">
                  <i class="fa fa-edit"></i>
                </router-link>
                <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['live_news_'+lang+'_logs_view'])"
                              v-tooltip.top-center="'Log'"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/live_news_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this live news?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <publish-modal :visible="publishModalVisibility"
                     :message="publishMessage"
                     :withTags="true"
                     :key="publishIncrementKey"
                     @close="closePublishModal()"
                     @confirm="confirmPublishModal">
      </publish-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import PublishModal from "../../../components/Modals/PublishModal";


export default {
  components: {
    PublishModal,
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    GeneralDataTable,
    FgSelect,
  },

  data() {
    return {
      tableColumns: [],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter:[],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      publishModalVisibility: false,
      publishMessage: 'Are you sue you want to publish this news?',
      toPublishId: undefined,
      socialMediaPublish: '',
      publishIncrementKey: 0,
      lang: 'ar',
      fixedColumn: '',
    }

  },
  created(){
    this.responsiveViewPort();
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
    this.axios.post(this.lang+"/live-news/builder").then((response) => {
      this.categoryList = response.data.categoryList;
      this.setAdvanceSearch();
    }).catch((error) => {
      console.error(error);
    });

  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {
      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete(this.lang+"/live-news/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Live News deleted successfully",
          timeout: 2000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },

    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    setAdvanceSearch(){
      this.advanceSearchFilter = [
        {
          key: 'live_news_'+this.lang+'.title',
          type: 'text',
          frontType:'simple',
          label: 'Title',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.url',
          type: 'text',
          frontType:'simple',
          label: 'Url',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.category_id',
          type: 'multiple-select',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: 'Category',
          options: this.categoryList,
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'Created after',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'Created before',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.date',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'after date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.date',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'before date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },



      ];
    },
    responsiveViewPort(){
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          this.fixedColumn = null;
          this.tableColumns = [
              {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
              {label: 'Type', value: 'type', minWidth: '200', align: 'center'},
          ];
      }else{
          this.fixedColumn = 'right';
          this.tableColumns = [
              {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
              {label: 'Category', value: 'category.name', minWidth: '200', align: 'center'},
              {label: 'Type', value: 'type', minWidth: '200', align: 'center'},
              {label: 'Date', value: 'date', minWidth: '200', align: 'center'},
          ];
      }
  },
    openPublishModal(id, socialMedia) {
      this.publishModalVisibility = true;
      this.toPublishId = id;
      this.publishMessage = "Are you sue you want to publish this news on "+socialMedia+" ?"
      this.socialMediaPublish = socialMedia;
      this.publishIncrementKey++;
    },
    closePublishModal() {
      this.publishModalVisibility = false;
    },
    confirmPublishModal(twitterTags) {
      let data = {
        'id': this.toPublishId,
        'twitter_tags': twitterTags
      }
      this.axios.post(this.lang+"/live-news/publish-"+this.socialMediaPublish, data).then((response) => {
        this.$notify({
          message: "Live News published on " + this.socialMediaPublish + " successfully",
          timeout: 2000,
          type: 'success'
        });
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closePublishModal();
      this.toPublishId = undefined;
    },

  },

}
</script>
