import DashboardLayout from "@/pages/layouts/DashboardLayout";

let usersRoutes = {
  path: '/users',
  component: DashboardLayout,
  redirect: '/users/list',
  children: [
    {
      path: 'list',
      name: 'Users List',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/users/UserList'),
      meta: {
        permissionsCodes: ['users/index'],
      }
    },
    {
      path: 'DeletedUsersList',
      name: 'Deleted Users List',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/users/DeletedUserList'),
      meta: {
        permissionsCodes: ['users/get-deleted-users'],
      }
    },
    {
      path: 'create',
      name: 'Add User',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/users/UserForm'),
      meta: {
        permissionsCodes: ['users/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit User',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/users/UserForm'),
      meta: {
        permissionsCodes: ['users/update'],
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change User Password',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/users/ChangeUserPassword'),
      meta: {
        permissionsCodes: ['users/change-password'],
      }
    },
  ]
}

export default usersRoutes;
