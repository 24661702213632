import DashboardLayout from "@/pages/layouts/DashboardLayout";

let pollRoutes = {
  path: '/polls',
  component: DashboardLayout,
  redirect: '/polls/list',
  children: [
    {
      path: 'list/ar',
      name: 'Poll List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollList'),
      meta: {
        permissionsCodes: ['ar/polls/index', 'restricted_own/ar/polls/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['ar/polls/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['ar/polls/update', 'restricted_own/ar/polls/update'],
      }
    }
  ]
}

export default pollRoutes;
