<template>
  <el-dialog
          center
          @close="closeModal()"
          :visible="visible">
    <div class="col-md-12">
      <paginated-select
        :labelTitle="'Choose Interview'"
        :isMultiple="true"
        @select-changed="draggableInterviewChanged"
        :apiUrl="'interviews/paginatedInterviews'"
        :lastData="draggableInterview"
        :selectLimiter="1"
        :lang="lang"
        :key="paginatedSelectKey"
      >
      </paginated-select>
    </div>
    <draggable class="container" v-model="draggableInterview" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableInterview" :key="'Item_'+x">
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>
      </div>
    </draggable>


    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
    import {Dialog, Option, Select} from 'element-ui'
    import {SelectPage} from 'v-selectpage'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import draggable from 'vuedraggable';
    import PaginatedSelect from "../../../../components/paginatedSelect";


export default {
    name: 'interview-section-modal',
    components: {
      PaginatedSelect,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
      [Option.name]: Option,
      'v-selectpage': SelectPage,
      vSelect,
      draggable,
    },

    props: {
        visible: {
            type: Boolean,
            default: 0
        },
        selectedInterview: {
            type: Array,
        },
    },
    data() {
      return {
        draggableInterview: [],
        interviewList: [],
        interviewCount: 0,
        selectKey: 0,
        paginatedSelectKey: 0,
        lang: 'ar'
      }
    },
    mounted() {
        this.draggableInterview = this.selectedInterview;
    },
    methods: {
      draggableInterviewChanged(selectedNews){
        this.draggableInterview = selectedNews
      },
      closeModal() {
          this.$emit('close');
      },
      confirmModal() {
          this.$emit('selectedInterview', this.draggableInterview);
          this.closeModal();
      },
      newsPaginated() {
          let helperData = {
              offset: this.offset,
              limit: this.limit,
              search: this.search,
              lang: this.lang,
          };
          this.axios.post("interviews/paginatedInterviews", helperData).then((response) => {
              this.interviewList = response.data.interviews;
              this.interviewsCount = response.data.interviewsCount;
          }).catch((error) => {
              console.error(error);
          })
      },
      moveEventFunction() {
          // this.news.map((element, index) => { element.sort_order =  index})
      },
    }

}
</script>
<style>

  .style-chooser .vs__selected {
    border-radius: 3px !important;
    border-color: #23CCEF;
    background-color: white;
    border-radius: 2px;
    color: #23CCEF;
  }

  .style-chooser .vs__deselect {
    background-color: transparent;
    color: #23CCEF;
    opacity: 1;
    font-size: 12px;
  }

  .style-chooser .vs__dropdown-option--selected {
    color: #23CCEF;
  }

  .style-chooser .prev-next {
    margin-top: 15px;
  }

  .style-chooser .prev-next button {
    margin-right: 15px;
    margin-left: 10px;
  }
</style>
