import DashboardLayout from "@/pages/layouts/DashboardLayout";

let interviewsRoutes = {
  path: '/interviews',
  component: DashboardLayout,
  redirect: '/interviews/list',
  children: [
    {
      path: 'list/ar',
      name: 'Interviews List',
      component: () => import(/* webpackChunkName: "interviews" */  '@/pages/interviews/InterviewsList'),
      meta: {
        permissionsCodes: ['ar/interviews/index', 'restricted_own/ar/interviews/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Interviews',
      component: () => import(/* webpackChunkName: "interviews" */  '@/pages/interviews/InterviewsForm'),
      meta: {
        permissionsCodes: ['ar/interviews/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Interviews',
      component: () => import(/* webpackChunkName: "interviews" */  '@/pages/interviews/InterviewsForm'),
      meta: {
        permissionsCodes: ['ar/interviews/update', 'restricted_own/ar/interviews/update'],
      }
    }
  ]
}

export default interviewsRoutes;
