<template>

  <div class="row" ref="interviewsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  vid="category_id"
                  rules="required"
                  name="The Category"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="category_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Category"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Category *'"
                    :list="categoryList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.category_id">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="title *"
                            name="title"
                            fou
                            @keyup="generateSomeFields"
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="sub_title"
                  rules="required"
                  name="The Sub Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="sub_title *"
                            name="sub_title"
                            fou

                            v-model="formData.sub_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="excerpt"
                  rules=""
                  name="The Excerpt"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="excerpt"
                           name="excerpt"
                           fou
                           v-model="formData.excerpt">
                  </fg-text>
                </ValidationProvider>
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.image"
                    :accepted-files="imagesExtensions">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init="editorConfig"
                  />
                </div>
                <ValidationProvider
                        vid="video_type"
                        rules=""
                        name="The Video Type"
                        v-slot="{ passed, failed,errors }">
                  <fg-select
                          name="video_type"
                          size="large"
                          filterable
                          clearable
                          placeholder="Video Type"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="'Video Type'"
                          :list="VideoTypes"
                          :listItemLabel="'label'"
                          :listItemValue="'value'"
                          v-model="formData.video_type">
                  </fg-select>
                </ValidationProvider>
                <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                  <label>Uploaded Video</label>
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{getBannerVideoInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.uploaded_video"
                    :accepted-files="videoExtensions"
                    :file-type="'video'">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group" v-if="formData.video_type === 'EMBEDDED'">
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{getBannerVideoInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <ValidationProvider
                          vid="embedded_video"
                          rules=""
                          name="The Embedded Video"
                          v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Embedded Video"
                              name="embedded_video"
                              fou
                              v-model="formData.embedded_video">
                    </fg-input>
                  </ValidationProvider>
                  <div v-html="formData.embedded_video">
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>Publish Status</h4>
                  <div class="col-md-12 col-sm-12">
                    <label class="card-label">News Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose News publish date"
                              readonly="true"
                    >
                    </fg-input>
                    <label class="card-label">Start Date</label>
                    <fg-input v-model="formData.start_date"
                              @change="startDateChangeEvent()"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose News publish date"
                    >
                    </fg-input>
                    <label class="card-label">End Date</label>
                    <fg-input v-model="formData.end_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose News expire date"
                    >
                    </fg-input>
                    <div class="form-group pt-4">
                      <label>Is Active</label>&nbsp;
                      <l-switch v-model="formData.is_active">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                    <div class="form-group pt-4">
                      <label>Publish on facebook page</label>&nbsp;
                      <l-switch v-model="formData.facebook_publish">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                  </div>
                </div>
                <div  class="col-md-12 col-sm-12 extra-fields">
                  <h4>A & S</h4>
                  <div class="d-flex justify-content-start">
                    <ValidationProvider
                      vid="author_id"
                      rules="required"
                      name="The Author"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="author_id"
                        class="px-3"
                        size="large"
                        filterable
                        clearable
                        placeholder="Author"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Author *'"
                        :list="authorList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.author_id">
                      </fg-select>
                    </ValidationProvider>
                    <div class="d-flex align-items-center mr-2">
                      <i v-if="$store.getters['auth/haveOneOfPermissions']([formData.lang+'/authors/create'])"
                         @click="openAddingModal('authors/create','New Author')"
                         type="button" class="fas fa-plus-circle align-middle" v-tooltip.top-center="'Add new Author'"></i>
                    </div>

                  </div>
                  <div class="d-flex justify-content-start">
                    <ValidationProvider
                      vid="source_id"
                      rules="required"
                      name="The Source"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="source_id"
                        size="large"
                        class="px-3"
                        filterable
                        clearable
                        placeholder="Source"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Source *'"
                        :list="sourceList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.source_id">
                      </fg-select>
                    </ValidationProvider>
                    <div class="d-flex align-items-center mr-2">
                      <i v-if="$store.getters['auth/haveOneOfPermissions']([formData.lang+'/sources/create'])"
                         @click="openAddingModal('sources/create','New Source')"
                         type="button" class="fas fa-plus-circle align-middle" v-tooltip.top-center="'Add new Source'"></i>
                    </div>

                  </div>
                </div>
                <div  class="col-md-12 col-sm-12 extra-fields">
                  <h4>Tags</h4>

                  <div class="col-md-12 col-sm-12">
                    <div class="d-flex justify-content-start">
                      <ValidationProvider
                        vid="tag_id"
                        rules=""
                        name="The Tag"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="tag_id"
                          size="large"
                          multiple
                          filterable
                          clearable
                          placeholder="Tag"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-info'"
                          :label="'Tags'"
                          :list="tagList"
                          :listItemLabel="'name'"
                          :listItemValue="'id'"
                          v-model="formData.tags">
                        </fg-select>
                      </ValidationProvider>
                      <div class="d-flex align-items-center ml-4">
                        <i v-if="$store.getters['auth/haveOneOfPermissions']([formData.lang+'/tags/create'])"
                           @click="openAddingModal('tags/create','New Tag')"
                           type="button" class="fas fa-plus-circle align-middle" v-tooltip.top-center="'Add new Tag'"></i>
                      </div>
                    </div>

                  </div>
                </div>
                <div  class="col-md-12 col-sm-12 extra-fields">
                  <h4>SEO Settings</h4>

                  <div class="col-md-12 col-sm-12">
                    <ValidationProvider
                            vid="url"
                            rules="required"
                            name="The URL"
                            v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="url *"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                            vid="meta_title"
                            rules=""
                            name="The Meta Title"
                            v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Meta Title"
                                name="meta_title"
                                fou
                                v-model="formData.meta_title">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                            vid="meta_description"
                            rules=""
                            name="The Meta Description"
                            v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Description"
                               name="meta_description"
                               fou
                               v-model="formData.meta_description">
                      </fg-text>
                    </ValidationProvider>
                    <ValidationProvider
                            vid="meta_keywords"
                            rules=""
                            name="The Meta Keywords"
                            v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Keywords"
                               name="meta_keywords"
                               fou
                               v-model="formData.meta_keywords">
                      </fg-text>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <multiple-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :filesNumber="5"
                    :accepted-files="imagesExtensions"
                    v-model="formData.gallery"
                  >
                  </multiple-file-uploader-file-manager>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/interviews/list/'+formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>
    <quick-adding-modal
      :visible="addingModalVisibility"
      :url="addingTypeUrl"
      :title= "addingModalTitle"
      @close="closeAddingModal()"
    >
    </quick-adding-modal>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip, TableColumn} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import { SelectPage } from 'v-selectpage'
import GeneralDataTable from "@/components/GeneralDataTable";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import QuickAddingModal from "@/pages/news/news/QuickAddingModal"
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";
import MultipleFileUploaderFileManager from "@/components/FileManager/MultipleFileUploaderFileManager";




extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    MultipleFileUploaderFileManager,
    SingleFileUploaderFileManager,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    PrimeVideoUploader,
    'editor': Editor,

    'v-selectpage': SelectPage,
    vSelect,
    QuickAddingModal
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions:this.getImagesExtensions(),
      videoExtensions:this.getVideoExtensions(),
      addingModalVisibility: false,
      addingTypeUrl:'',
      addingModalTitle:'',
      formData: {
        category_id: "",
        source_id: "",
        author_id: "",
        lang: "",
        tags: [],
        title: "",
        sub_title: "",
        excerpt: "",
        url: "",
        content: "",
        image: "",
        file: "",
        date:"",
        start_date: null,
        end_date: null,
        is_active: true,
        gallery: [],
        facebook_publish: false,
        video_type: '',
        uploaded_video: "",
        embedded_video: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
      },

      categoryList: [],
      sourceList: [],
      authorList: [],
      tagList: [],
      interviewsList: [],
      VideoTypes: [],
      search: '',
      offset: 0,
      limit: 5,
      interviewsCount: 0,
      selectKey: 0
    };
  },

  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
    paginated() {
      return this.interviewsList;
    },
    hasNextPage() {
      if (this.interviewsList.length < 5) {
        return false
      }
      return true
    },
    hasPrevPage() {
      if (this.offset == 0){
        return false
      }
      return true
    },
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.interviewsForm
    });
    this.getBuilderData();

  },


  methods: {
    getBuilderData(){
      let data = {lang: this.formData.lang};
      this.axios.post("interviews/builder", data).then((response) => {
        this.categoryList = response.data.categories;
        this.sourceList = response.data.sources;
        this.authorList = response.data.authors;
        this.tagList = response.data.tags;
        this.VideoTypes = response.data.videoTypes;
        this.id = this.$route.params['id'];
        this.interviewsPaginted();
        if (this.id !== undefined) {
          this.editMode = true;
          this.formTitle = "Edit Interview";
          this.getNews();
        } else {
          this.editMode = false;
          this.formTitle = "Add Interview";
          this.formData.date = this.getDateNow();
          this.loader.hide();
        }

      }).catch((error) => {
        console.error(error);
      });
    },
    getNews() {
        let data = {
            id: this.id,
            lang: this.formData.lang
        }
      this.axios.post("interviews/get", data).then((response) => {
        this.formData = response.data;
        this.formData.tags = response.data.tags.map(tag => tag.id);
        this.selectKey++;
        this.formData.gallery = response.data.gallery;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Interview Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
        if (this.formData.video_type == "UPLOADED"){
            this.formData.embedded_video = '';
        }else if (this.formData.video_type == "EMBEDDED") {
            this.formData.uploaded_video = '';
        }else{
            this.formData.embedded_video = '';
            this.formData.uploaded_video = '';
        }
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("interviews/update/" + this.id, this.formData);
        successMessage = "Interview Updated Successfully";
      } else {
        request = this.axios.post("interviews/create", this.formData);
        successMessage = "Interview Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/interviews/list/"+this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        }else if(error.response.status === 410) {
          this.$notify({
            message: error.response.data.message,
            timeout: 3000,
            type: 'danger'
          })
        }  else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["interviews_image"]) {
        return this.mediaInfo["interviews_image"];
      } else {
        return "";
      }
    },
    generateSomeFields() {

      let newUrl = '';
      if (this.formData.title) {
        this.formData.meta_title = this.formData.title;
        this.formData.meta_description = this.formData.title;
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },
    onSearch(query) {
      this.search = query
      this.offset = 0
      this.interviewsPaginted();
    },
    interviewsPaginted(){
      let helperData = {
        offset: this.offset,
        limit: this.limit,
        search: this.search,
        lang: this.formData.lang
      };
      this.axios.post("interviews/paginatedInterviews", helperData).then((response) => {
        this.interviewsList = response.data.interviews;
        this.interviewsCount = response.data.interviewsCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    nextPage(){
      this.offset += this.limit;
      this.interviewsPaginted();
    },
    prevPage(){
      this.offset -= this.limit;
      this.interviewsPaginted();
    },
    getBannerVideoInfo() {
      if(this.mediaInfo && this.mediaInfo["interviews_video"]) {
        return this.mediaInfo["interviews_video"];
      } else {
        return "";
      }
    },
    startDateChangeEvent(){
        if (!this.formData.start_date){
            this.formData.date = this.getDateNow();
        }else{
            this.formData.date = this.formData.start_date;
        }
    },
    getDateNow(){
        var d = new Date,
            dformat = [
                    d.getFullYear(),
                    ("0" + (d.getMonth()+1)).slice(-2),
                    ("0" + d.getDate()).slice(-2)
                ]
                    .join('-')+'T'+
                [
                    ("0" + d.getHours()).slice(-2),
                    ("0" + d.getMinutes()).slice(-2),
                    ("0" + d.getSeconds()).slice(-2)
                ]
                    .join(':');

        return dformat;
    },
      vueDate(){
          this.formData.start_date = (this.formData.start_date)? this.formData.start_date.replace(' ', 'T'):this.formData.start_date;
          this.formData.end_date = (this.formData.end_date)? this.formData.end_date.replace(' ', 'T'):this.formData.end_date;
          this.formData.date = (this.formData.date)? this.formData.date.replace(' ', 'T'):this.formData.date;
      },
      laravelDate(){
          this.formData.start_date = (this.formData.start_date)? this.formData.start_date.replace('T', ' '):this.formData.start_date;
          this.formData.end_date = (this.formData.end_date)? this.formData.end_date.replace('T', ' '):this.formData.end_date;
          this.formData.date = (this.formData.date)? this.formData.date.replace('T', ' '):this.formData.date;
      },
    openAddingModal(addingTypeUrl,title) {
      this.addingTypeUrl = addingTypeUrl;
      this.addingModalTitle = title;
      this.addingModalVisibility = true;
    },
    closeAddingModal() {
      this.getBuilderData();
      this.addingModalVisibility = false;
    },


  }
}
</script>

<style>

.extra-fields{
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}.extra-fields >h4{
   padding:20px;
 }
.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}
.style-chooser .vs__deselect{
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}
.style-chooser .vs__dropdown-option--selected{
  color: #23CCEF;
}
.style-chooser .prev-next{
  margin-top: 15px;
}
.style-chooser .prev-next button{
  margin-right: 15px;
  margin-left: 10px;
}
</style>
