<template>
  <activity-logs-table
    :apiUrl="'logs/getRecordLogs'"
    :advanceSearchFilter="advanceSearchFilter"
    :title="'History'"
    :dataParam="dataParam"
  ></activity-logs-table>
</template>
<script>
import ActivityLogsTable from "@/pages/activityLogs/ActivityLogsTable"


export default {
  components: {
    ActivityLogsTable
  },

  data() {

    return {
      advanceSearchFilter: [
        {
          key: 'activity_logs.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'activity_logs.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
      ],
      dataParam: {"identifier":this.$route.params['id']},
    }

  },

  mounted() {
  },

  methods: {
  },

}
</script>
