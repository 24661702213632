<template>
    <el-dialog
            v-if="poll"
            center
            @close="closeModal()"
            :visible="visible">
        <h5 style="text-align: center">{{poll.question }}</h5>

        <div class="searchable-container">
            <div v-for="(answer, answerIndex)  in poll.back_answers" class="items col-xs-12">
                <div class="info-block block-info clearfix">
                    <h4>{{ answer.text }}</h4>
                    <h6 class="badge badge-info percentage-badge">Real Voters Count: {{ answer.real_counter}}</h6>
                    <h6 class="badge badge-info">Real Percentage: {{ answer.real_percentage}} %</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <fg-input type="number"
                                      min="0"
                                      :key="answerIndex"
                                      :label="'Change voters count'"
                                      v-model="answer.edited_counter"
                                      @keyup="getPercentage"
                                      @change="getPercentage">
                            </fg-input>
                        </div>
                        <div class="col-md-3 align-self-center mt-3">
                            <span class="badge badge-info" :key="editedPercentageKey">Edited Percentage {{ answer.edited_perecntage }} %</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
    </el-dialog>
</template>

<script>
    import {Dialog} from 'element-ui'

    export default {
        name: 'edit-percentage-modal',

        components: {
            [Dialog.name]: Dialog,
        },

        props: {
            visible: {
                type: Boolean,
                default: 0
            },
            poll: {},
        },
        data(){
            return{
                editedPercentageKey: 0,
            }
        },
        mounted(){
            if (Object.keys(this.poll).length != 0){
                this.getPercentage();
            }
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            confirmModal() {
                this.$emit('updatePercentage', this.poll);
            },
            getPercentage(){
                let newEditedCounter = this.poll.back_answers.reduce((sum, item) => this.parseInteger(sum) + this.parseInteger(item.edited_counter), 0);
                this.poll.back_answers.forEach(function (text) {
                    text.edited_counter = text.edited_counter != '' ? text.edited_counter : 0;
                    text.edited_perecntage  = newEditedCounter > 0 ? parseInt(text.edited_counter / newEditedCounter * 100) : 0;
                });
                this.editedPercentageKey++;
            },
            parseInteger(value){
                if (value && value != ''){
                    return parseInt(value);
                }
                return 0;
            }

        }

    }
</script>

<style>

    .info-block
    {
        padding: 0px 10px;
        box-shadow: 1px 1px 12px 0px #ccc, -1px -1px 12px 0px #ccc;
    }

    .info-block.block-info
    {
        border-color:#23CCEF
    }
    .percentage-badge{
        margin-right: 5px;
    }
</style>
