<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
    </legend>
    <div class="row">
      <div class="col-md-6">
        <fieldset class="ml-1 mt-4">
          <legend>
            <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_category_section_'+section])"
              @click="openSectionModal(sectionSelectedData.column2,'column_2')" class="fas fa-pen visual-editor-btn"></i>
          </legend>
          <div class="section-header d-flex justify-content-end" v-if="sectionSelectedData.column2">
            <label v-if="sectionSelectedData.column2.type == 'category'">{{ sectionSelectedData.column2.category ? sectionSelectedData.column2.category.name : '' }}</label>
            <label v-else>{{ sectionSelectedData.column2.classification ? sectionSelectedData.column2.classification.name : '' }}</label>
          </div>
          <category-section-news
            v-if="sectionSelectedData.column2.news"
            :key="counterKey"
            :news="sectionSelectedData.column2.news"
          >
          </category-section-news>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="ml-1 mt-4">
          <legend>
            <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_category_section_'+section])"
              @click="openSectionModal(sectionSelectedData.column1,'column_1')" class="fas fa-pen visual-editor-btn"></i>
          </legend>
          <div class="section-header d-flex justify-content-end">
            <label v-if="sectionSelectedData.column1.type == 'category'">{{ sectionSelectedData.column1.category ? sectionSelectedData.column1.category.name : '' }}</label>
            <label v-else>{{ sectionSelectedData.column1.classification ? sectionSelectedData.column1.classification.name : '' }}</label>
          </div>
          <category-section-news
            v-if="sectionSelectedData.column1.news"
            :key="counterKey"
            :news="sectionSelectedData.column1.news"
          >
          </category-section-news>
        </fieldset>
      </div>
    </div>
    <category-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_category_section_'+section])"
      :visible="sectionModalVisibility"
      :selectedData="modalSelectedData"
      :categoriesData="categoriesData"
      :column="modalColumn"
      :section="section"
      :classificationData="classificationData"
      @close="closeSectionModal"
      @saveData="saveData"
    >

    </category-section-modal>
  </fieldset>
</template>

<script>
import CategorySectionModal from '@/pages/visualEditors/homePage/categorySection/CategorySectionModal';
import CategorySectionNews from '@/pages/visualEditors/homePage/categorySection/CategorySectionNews';

export default {
  name: 'category-section',
  components: {
    CategorySectionModal,
    CategorySectionNews
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        if (newValue) {
          this.sectionSelectedData = newValue;
        }
      },
      deep: true
    }
  },
  props: {
    sectionData: {
      type: Object,
    },
    lang:{
      type:String,
      default:'ar'
    },
    section: {
      type: String,
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
  },
  mounted() {

  },
  data() {
    return {
      modalSelectedData: {},
      sectionSelectedData: {
        column1: {type:'',category: null,classification: null, news: []},
        column2: {type:'',category: null,classification: null, news: []},
      },
      sectionModalVisibility: false,
      modalColumn: '',
      counterKey:'',
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSectionModal(sectionData,column) {
      this.modalSelectedData= {...sectionData};
      this.modalColumn= column;
      this.sectionModalVisibility = true;
    },
    closeSectionModal() {
      this.sectionModalVisibility = false;
    },
    saveData(value) {
      value.lang = this.lang;
      this.$emit('saveData', value);
    },
  }

}
</script>
<style>
</style>
