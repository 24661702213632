import DashboardLayout from "@/pages/layouts/DashboardLayout";

let activityLogsRoutes = {
  path: '/logs',
  component: DashboardLayout,
  redirect: '/logs/list',
  children: [
    // {
    //   path: 'list',
    //   name: 'Activity Logs List',
    //   component: () => import(/* webpackChunkName: "branches" */  '@/pages/pages/branches/BranchList'),
    //   meta: {
    //     permissionsCodes: 'branches/index',
    //   }
    // },
    // {
    //   path: 'create',
    //   name: 'Add Branch',
    //   component: () => import(/* webpackChunkName: "branches" */  '@/pages/pages/branches/BranchForm'),
    //   meta: {
    //     permissionsCodes: 'branches/create',
    //   }
    // },
    {
      path: 'user-actions/:id',
      name: 'User Actions',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/activityLogs/UserActions'),
      meta: {
        // permissionsCodes: 'branches/update',
      }
    },

      {
      path: ':id',
      name: 'Item History',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/activityLogs/ItemHistory'),
      meta: {
        // permissionsCodes: 'branches/update',
      }
    }
  ]
}

export default activityLogsRoutes;
