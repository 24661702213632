<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">{{ message }}</h5>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>

    <div v-if="withTags" class="col-md-12 col-sm-12 col-md-6 extra-fields">
      <h4 style="display: inline-block"><i class="fab fa-twitter mr-2" style="color:#1d9bf0"></i>Twitter Tags</h4>
      <div class="col-md-12">
        <div class="row" v-for="(tag,index) in twitterTags">
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-12">
                <fg-input type="text"
                          :key="index"
                          :label="'HashTag '+(index+1)"
                          v-model="twitterTags[index]">
                </fg-input>
              </div>
            </div>
          </div>
          <div class="col-sm-2 align-self-center mt-3">
            <l-button
              @click="removeTwitterTag(index)"
              type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
            </l-button>
          </div>
        </div>
      </div>
      <l-button
        type="success"
        @click="addNewTwitterTag()"
        size="sm">
                       <span class="btn-label">
                         <i class="fa fa-plus"></i>
                         </span>
      </l-button>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'
import LSwitch from "../Switch";

export default {
  name: 'publish-modal',

  components: {
    LSwitch,
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    message: {
      type: String,
      default: "Are you sue you want to publish this news?",
    },
    withTags: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      twitterTags: [],
    }
  },
  mounted() {
    this.twitterTags = [];
  },
  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm', this.twitterTags);
    },
    addNewTwitterTag() {
      this.twitterTags.push('');
    },

    removeTwitterTag(index) {
      this.twitterTags.splice(index, 1);
    },

  }

}
</script>
