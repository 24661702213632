import DashboardLayout from "@/pages/layouts/DashboardLayout";

let panelRoutes = {
  path: '/panels',
  component: DashboardLayout,
  redirect: '/panels/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Panels List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelList'),
      meta: {
        permissionsCodes: ['ar/panels/index','restricted_own/ar/panels/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Panel',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelForm'),
      meta: {
        permissionsCodes: ['ar/panels/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Panel',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelForm'),
      meta: {
        permissionsCodes: ['ar/panels/update','restricted_own/ar/panels/update'],
      }
    }
  ]
}

export default panelRoutes;
