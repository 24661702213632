<template>
  <div class="row">
    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">notification List</h4>
          <div class="d-flex justify-content-start">
            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/notifications/create'])"
                         :to="'/notifications/create/'+ lang"
                         class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>


        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :lang="lang"
            :row-key="'id'"
            :api-url="'notifications/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="150"
              :fixed="fixedColumn"
              label="queued_at">
              <template slot-scope="scope">
                <div>{{(new Date(scope.row.queued_at * 1000)).toLocaleString()}}</div>
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--              :min-width="120"-->
<!--              fixed="right"-->
<!--              align="center"-->
<!--              label="Actions">-->
<!--              <template slot-scope="scope">-->
<!--                <div></div>-->
<!--              </template>-->
<!--            </el-table-column>-->

          </general-data-table>
        </div>

      </card>

    </div>
  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    [TableColumn.name]: TableColumn,
    GeneralDataTable,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Body', value: 'contents.en', minWidth: '200', align: 'center'},
        {label: 'received', value: 'received', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      lang:'',
    }

  },
  created(){
    this.responsiveViewPort();
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  methods:{
      responsiveViewPort(){
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
              this.fixedColumn = null;
          }else{
              this.fixedColumn = 'right';
          }
      }
  }
}

</script>
