<template>

  <div class="row" ref="pollForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <ValidationProvider
                    vid="question"
                    rules="required"
                    name="The Question"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Question *"
                              name="question"
                              fou
                              v-model="formData.question">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <div class="row" v-for="(answer,answerIndex) in formData.answers">
                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-md-9">
                          <fg-input type="text"
                                    :key="answerIndex"
                                    :label="'Answer '+(answerIndex+1)"
                                    v-model="answer.text">
                          </fg-input>
                        </div>
                      </div>
                    </div>
                    <div v-if="answerIndex > 0" class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeAnswer(answerIndex)"
                        type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
                      </l-button>
                    </div>
                  </div>
                  <l-button
                    type="success"
                    @click="addNewAnswer()"
                    size="sm">
                       <span class="btn-label">
                         <i class="fa fa-plus"></i>
                         </span>
                  </l-button>
                </div>
              </div>

               <div class="row">
                
                <div class="col-xs-12 col-sm-12 col-md-6">
                 
                 
                  <div class="form-group pt-4">
                                  <label>Show inside pages</label>&nbsp;
                                  <l-switch v-model="formData.show_inside_pages">
                                    <i class="fa fa-check" slot="on"></i>
                                    <i class="fa fa-times" slot="off"></i>
                                  </l-switch>
                    </div> 

              </div>
              </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/polls/list/'+formData.lang)" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate"; 

extend("required", {
  message: "{_field_} is required"
});
import {Switch as LSwitch} from "@/components";
export default {
  components: {
      LSwitch,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        question: "",
        answers: [],
        lang: 'ar',
        start_date: null,
        end_date: null,
        is_active: false,
        show_inside_pages: true,
        edited_counter: 0,
      },

    };
  },
  created(){
      const path = window.location.href;
      this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.pollForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Poll";
      this.getPoll();
    } else {
      this.editMode = false;
      this.formTitle = "Add Poll";
      this.loader.hide();
    }

  },


  methods: {

    async getPoll() {
      try {
        let data = {
            id: this.id,
            lang: this.formData.lang
        };
        let response = await this.axios.post("polls/get-poll", data);
        this.formData = response.data;
        let isEdited = this.formData.answers.every(a => a.edited_counter === 0);
        if (isEdited){
            this.formData.answers.forEach(function (item, index) {
                item.edited_counter = response.data.answers[index].real_counter;
            })
            this.getPercentage();
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Poll Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() { 
        const emptyAnswer = this.formData.answers.some(el => el.text === '');
        if (emptyAnswer){
            this.$notify({
                message: "You have to fill all Answers or delete the empty one",
                timeout: 1000,
                type: 'danger'
            });
            return;
        }
      let request;
      let successMessage;
      this.submitting = true;
      // this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("polls/update/" + this.id, this.formData);
        successMessage = "Poll Updated Successfully";
      } else {
        request = this.axios.post("polls/create", this.formData);
        successMessage = "Poll Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/polls/list/"+this.formData.lang);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    addNewAnswer() {
      this.formData.answers.push({"text": "", "real_counter": 0, "edited_counter": 0});
      this.getPercentage();
    },

    removeAnswer(index) {
      this.formData.answers.splice(index, 1);
      this.getPercentage();
    },
    getPercentage(){
        let newEditedCounter = this.formData.answers.reduce((sum, item) => this.parseInteger(sum) + this.parseInteger(item.edited_counter), 0);
        this.formData.answers.forEach(function (text) {
            text.edited_counter = text.edited_counter != '' ? text.edited_counter : 0;
            text.edited_perecntage = parseInt(text.edited_counter / newEditedCounter * 100);
        });
    },
    parseInteger(value){
        if (value && value != ''){
            return parseInt(value);
        }
        return 0;
    }

  }
}
</script>

<style>
</style>
