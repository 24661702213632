<template>

  <div class="row" ref="panelForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="group_id"
                      rules="required"
                      name="The Group"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="group_id"
                        size="large"
                        filterable
                        clearable
                        placeholder="Group"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Group *'"
                        :list="groupList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.group_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="name *"
                                name="name"
                                @keyup="generateSomeFields"
                                fou
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="ident"
                      rules="required"
                      name="The Ident"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                :disabled="editMode === true"
                                label="Panel Ident *"
                                name="ident"
                                fou
                                v-model="formData.ident">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <fg-input type="text"
                          label="Main Heading"
                          name="main_heading"
                          fou
                          v-model="formData.main_heading">
                </fg-input>
                <ValidationProvider
                  vid="google_ads_code"
                  rules=""
                  name="The Google ads code"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="google_ads_code"
                            name="google_ads_code"
                            fou
                            v-model="formData.google_ads_code">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
              <div class="col-md-12 col-sm-12">

                <div class="form-group">
                  <label>Image</label>
                  <single-file-uploader-file-manager
                    v-model="formData.image"
                    :accepted-files="imagesExtensions">
                  </single-file-uploader-file-manager>
                </div>
                <ValidationProvider
                  vid="video_type"
                  rules=""
                  name="The Video Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="video_type"
                    size="large"
                    filterable
                    clearable
                    placeholder="Video Type"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Video Type'"
                    :list="VideoTypes"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.video_type">
                  </fg-select>
                </ValidationProvider>
                <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                  <label>Uploaded Video</label>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.uploaded_video"
                    :accepted-files="videoExtensions"
                    :file-type="'video'">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group" v-if="formData.video_type == 'EMBEDDED'">
                  <ValidationProvider
                    vid="embedded_video"
                    rules=""
                    name="The Embedded Video"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Embedded Video"
                              name="embedded_video"
                              fou
                              v-model="formData.embedded_video">
                    </fg-input>
                  </ValidationProvider>
                  <div v-html="formData.embedded_video">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="url_target"
                      size="large"
                      filterable
                      clearable
                      placeholder="Redirection Type"
                      :input-classes="'select-default'"
                      :label="'Target'"
                      :list="redirectionTargets"
                      :listItemLabel="'label'"
                      :listItemValue="'value'"
                      v-model="formData.url_target">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="redirection_type"
                      name="Redirection Type"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="redirection_type"
                        size="large"
                        filterable
                        clearable
                        :error="failed ? errors[0]: null"
                        placeholder="Redirection Type"
                        :input-classes="'select-default'"
                        :label="'redirection Type'"
                        :list="redirectionTypes"
                        :listItemLabel="'label'"
                        :listItemValue="'value'"
                        v-model="formData.redirection_type">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-input type="text"
                              label="Destination Url"
                              name="destination_url"
                              fou
                              v-model="formData.destination_url">
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <fg-input type="text"
                              label="Destination Url Title"
                              name="destination_url_title"
                              fou
                              v-model="formData.destination_url_title">
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="card-label">Start Date</label>
                    <fg-input v-model="formData.start_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose panel publish date"
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <label class="card-label">End Date</label>
                    <fg-input v-model="formData.end_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose panel expire date"
                    >

                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-input type="text"
                              label="ad Client"
                              name="ad_client"
                              fou
                              v-model="formData.ad_client">
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <fg-input type="text"
                              label="ad Slot"
                              name="ad_slot"
                              fou
                              v-model="formData.ad_slot">
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-input type="text"
                              label="ad Format"
                              name="ad_format"
                              fou
                              v-model="formData.ad_format">
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                    name="ads_mode"
                    size="large"
                    filterable
                    clearable
                    placeholder="ads Mode"
                    :input-classes="'select-default'"
                    :label="'ads Mode'"
                    :list="adsModeList"
                    :listItemLabel="'value'"
                    :listItemValue="'value'"
                    v-model="formData.ads_mode">
                  </fg-select>
                  </div>
                </div>
                <div class="form-group pt-4">
                  <label>full Width Responsive</label>&nbsp;
                  <l-switch v-model="formData.full_width_responsive">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/panels/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      imagesExtensions:this.getImagesExtensions(),
      videoExtensions:this.getVideoExtensions(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        group_id: "",
        name: "",
        ident: "",
        main_heading: "",
        content: "",
        image: "",
        destination_url: "",
        destination_url_title: "",
        url_target: "",
        start_date: null,
        end_date: null,
        is_active: true,
        redirection_type: "",
        google_ads_code: "",
        lang: 'ar',
        video_type: '',
        uploaded_video: "",
        embedded_video: "",
        ad_client: "",
        ad_slot: "",
        ad_format: "",
        ads_mode: "",
        full_width_responsive: true,
      },

      groupList: [],
      redirectionTargets: [],
      redirectionTypes: [],
      VideoTypes: [],
      adsModeList: [
        {value: 'google'},
        {value: 'panel'},
      ]

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  created() {
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.panelForm
    });

    let data = {
      'lang': this.formData.lang
    }
    this.axios.post("panels/builder", data).then((response) => {
      this.groupList = response.data.groups;
      this.redirectionTargets = response.data.redirectionTargets;
      this.redirectionTypes = response.data.redirectionTypes;
      // this.formData.redirection_type = response.data.redirectionTypes[0].value;
      this.VideoTypes = response.data.videoTypes;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Panel";
        this.getPanel();
      } else {
        this.editMode = false;
        this.formTitle = "Add Panel";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPanel() {
      let data = {
        'id': this.id,
        'lang': this.formData.lang
      };
      this.axios.post("panels/get", data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Panel Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    generateSomeFields() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.ident = newUrl;
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.formData.video_type == "UPLOADED") {
        this.formData.embedded_video = '';
      } else {
        this.formData.uploaded_video = '';
      }
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("panels/update/" + this.id, this.formData);
        successMessage = "Panel Updated Successfully";
      } else {
        request = this.axios.post("panels/create", this.formData);
        successMessage = "Panel Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/panels/list/" + this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if (this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

    getBannerVideoInfo() {
      if (this.mediaInfo && this.mediaInfo["panel_video"]) {
        return this.mediaInfo["panel_video"];
      } else {
        return "";
      }
    },
    vueDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace(' ', 'T') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace(' ', 'T') : this.formData.end_date;
    },
    laravelDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace('T', ' ') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace('T', ' ') : this.formData.end_date;
    }

  }
}
</script>
