<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i  v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_1'])"
        @click="openFirstSectionModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="row container mt-5">
      <div class="col-md-3">
        <div class="image-card-section-1 text-right" v-if="selectedNews[3]">
          <img class="card-img-top" :src="getUrl()+selectedNews[3].image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ selectedNews[3].title}}</p>
        </div>

        <div class="image-card-section-1 text-right" v-if="selectedNews[4]">
          <img class="card-img-top" :src="getUrl()+selectedNews[4].image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ selectedNews[4].title}}</p>
        </div>

      </div>
      <div class="col-md-6">
        <div class="image-card-section-1 section-1-big-img text-right" v-if="selectedNews[2]">
          <img class="card-img-top pt-5" :src="getUrl()+selectedNews[2].image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ selectedNews[2].title}}</p>
        </div>

      </div>
      <div class="col-md-3">

        <div class="image-card-section-1 text-right" v-if="selectedNews[0]">
          <img class="card-img-top" :src="getUrl()+selectedNews[0].image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ selectedNews[0].title}}</p>
        </div>

        <div class="image-card-section-1 text-right" v-if="selectedNews[1]">
          <img class="card-img-top" :src="getUrl()+selectedNews[1].image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ selectedNews[1].title}}</p>
        </div>

      </div>
    </div>
    <first-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_1'])"
      :visible="firstSectionModalVisibility"
      :selectedNews="modalSelectedList"
      @close="closeFirstSectionModal"
      @selectedNews="saveData"
    >

    </first-section-modal>
  </fieldset>
</template>

<script>
import FirstSectionModal from '@/pages/visualEditors/homePage/firstSection/FirstSectionModal.vue';

export default {
  name: 'first-section',
  components: {
    FirstSectionModal
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        this.setModalData();
        this.modalSelectedList = this.selectedNews;
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Array,
    },
    lang:{
      type: String
    },
  },
  mounted() {},
  data() {

    return {
      modalSelectedList: [],
      firstSectionModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openFirstSectionModal() {
      this.firstSectionModalVisibility = true;
    },
    closeFirstSectionModal() {
      this.firstSectionModalVisibility = false;
    },
    setModalData(){
      this.selectedNews.map((element, index) => {
        this.modalSelectedList.push({id: element.id, title: element.title});
      })
    },
    saveData(value) {
      value.map((element, index) => {
        element.sort_order =  index+1;
      });
      let data = {
        'key': 'ar_home_page_section_1',
        'value': value,
        'lang':this.lang,
      }
      this.$emit('saveData', data);
    }
  }

}
</script>
<style>

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  margin: 5px 5px 1.5em 5px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.visual-editor-btn {
  cursor: pointer;
}

.section1-part1 {
  height: 407px;
}

.section1-part2 {
  height: 120px;
}

.section1-part1-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}

.section1-part2-larg-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.image {
  width: 100%;
  height: 100%;
}

.text {
  font-size: 12px;
  color: white;
  text-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.5);
  margin: 5px;
  position: absolute;
}
.image-card-section-1{
  position: relative;
  width: 14rem;
  height: 175px;
  margin-right: 10px;
  margin-bottom: 20px;
  display: inline-block;
}
.section-1-big-img{
  width:100% !important;
  height:100% !important;
}
.image-card-section-1 img{
  width: 100%;
  height: 100%;
}
.image-card-section-1 p{
  position: absolute;
  left: 0;
  bottom: 0px;
  max-width: 200px;
  background-color: #ffffff;
  color: #000000;
  height: 62px;
  white-space: break-spaces;
  min-width: 75%;
}
</style>
