<template>

  <div class="row" ref="redirectionForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

            <ValidationProvider
              vid="parent_id"
              rules="required"
              name="The Parent"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="parent_id"
                size="large"
                filterable
                clearable
                placeholder="Parent"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Parent'"
                :list="pageList"
                :listItemLabel="'label'"
                :listItemValue="'id'"
                v-model="formData.parent_id">
              </fg-select>
            </ValidationProvider>

            <ValidationProvider
              vid="page_name"
              rules="required"
              name="The Page Name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Page Name"
                        name="page_name"
                        fou
                        v-model="formData.page_name">
              </fg-input>
            </ValidationProvider>


            <ValidationProvider
              vid="redirection_type"
              rules="required"
              name="The Redirection Type"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="redirection_type"
                size="large"
                filterable
                clearable
                placeholder="Redirection Type"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Redirection Type'"
                :list="redirectionTypes"
                :listItemLabel="'label'"
                :listItemValue="'value'"
                v-model="formData.redirection_type">
              </fg-select>
            </ValidationProvider>

            <ValidationProvider
              vid="redirection_url"
              rules="required"
              name="The Redirection Url"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Redirection Url"
                        name="redirection_url"
                        fou
                        v-model="formData.redirection_url">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="redirection_target"
              rules="required"
              name="The Redirection Target"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="redirection_target"
                size="large"
                filterable
                clearable
                placeholder="Redirection Type"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Redirection Target'"
                :list="redirectionTargets"
                :listItemLabel="'label'"
                :listItemValue="'value'"
                v-model="formData.redirection_target">
              </fg-select>
            </ValidationProvider>

            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/pages/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import {Switch as LSwitch} from "@/components";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    FgSelect,
    LSwitch
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        page_name: "",
        parent_id: "",
        redirection_type: "",
        redirection_url: "",
        redirection_target: "",
        is_active: true,
        lang: 'ar',
      },

      redirectionTypes: [],
      redirectionTargets: [],
      pageList: []

    };
  },
  created(){
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.redirectionForm
    });

      let data = {
          'lang': this.formData.lang
      }
    this.axios.post("redirection-pages/builder", data).then((response) => {

      this.redirectionTypes = response.data.redirectionTypes;
      this.redirectionTargets = response.data.redirectionTargets;
      this.pageList = response.data.pageList;
      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Redirection Page";
        this.getPage();
      } else {
        this.editMode = false;
        this.formTitle = "Add Redirection Page";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPage() {
      let data = {
          'id' : this.id,
          'lang' : this.formData.lang
      };
      this.axios.post("redirection-pages/get", data).then((response) => {
        Object.keys(this.formData).forEach(key=>{
           this.formData[key]=response.data[key];
        });
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Page Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("redirection-pages/update/" + this.id, this.formData);
        successMessage = "Page Updated Successfully";
      } else {
        request = this.axios.post("redirection-pages/create", this.formData);
        successMessage = "Page Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/pages/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>
