<template>
  <div class="row">

    <div class="col-12">
      <card>
        <first-section :lang="lang" :selectedNews="firstSectionData" @saveData="saveSectionData"></first-section>
      </card>
    </div>
    <div class="col-12">
        <second-section
          :lang="lang"
          :sectionData="classificationSection1Data"
          :categoriesData="categoriesData"
          :classificationData="classificationData"
          @saveData="saveSectionData"></second-section>
    </div>
    <div class="col-12">
      <card>
        <third-section :selectedNews="thirdSectionData" @saveData="saveSectionData"></third-section>
      </card>
    </div>
    <div class="col-12">
      <category-section
        :section="'1'"
        :sectionData="homePageCategorySection1"
        :categoriesData="categoriesData"
        :classificationData="classificationData"
        @saveData="saveSectionData"></category-section>
    </div>

    <div class="col-12">
      <card>
        <interview-section :selectedInterview="interviewData" @saveData="saveSectionData"></interview-section>
      </card>
    </div>

    <div class="col-12">
      <category-section
        :section="'2'"
        :sectionData="homePageCategorySection2"
        :categoriesData="categoriesData"
        :classificationData="classificationData"
        @saveData="saveSectionData"></category-section>
    </div>
    <div class="col-12">
      <card>
        <seventh-section
          :sectionData="homePageSection7"
          :categoriesData="categoriesData"
          :classificationData="classificationData"
          @saveData="saveSectionData"></seventh-section>
      </card>
    </div>

    <div class="col-12">
      <card>
        <eighth-section
          :sectionData="homePageSection8"
          :categoriesData="categoriesData"
          :classificationData="classificationData"
          @saveData="saveSectionData"></eighth-section>
      </card>
    </div>


  </div>
</template>
<script>
import FirstSection from '@/pages/visualEditors/homePage/firstSection/FirstSection';
import ThirdSection from "./thirdSection/ThirdSection";
import SecondSection from '@/pages/visualEditors/homePage/secondSection/SecondSection';
import InterviewSection from "./interviewSection/InterviewSection";
import CategorySection from '@/pages/visualEditors/homePage/categorySection/CategorySection';
import SeventhSection from '@/pages/visualEditors/homePage/seventhSection/SeventhSection';
import EighthSection from '@/pages/visualEditors/homePage/eighthSection/EighthSection';

export default {
  components: {
    ThirdSection,
    FirstSection,
    SecondSection,
    CategorySection,
    InterviewSection,
    SeventhSection,
    EighthSection,
  },
  data() {

    return {
      firstSectionModalVisibility: false,
      lang: 'ar',
      firstSectionData: [],
      secondSectionList: [],
      thirdSectionData: {},
      interviewData: [],
      classificationSection1Data:{},
      homePageCategorySection1:{},
      homePageCategorySection2:{},
      homePageSection7:{},
      homePageSection8:{},
      classificationData: [],
      categoriesData:[],
    }
  },
  mounted() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
    this.getVisualEditor();
    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    if (this.$store.getters['auth/haveOneOfPermissions'](
      [
      'ar_home_page_category_section_1', 'ar_home_page_category_section_2',
      'ar_home_page_section_8', 'ar_home_page_section_7',
      'ar_home_page_classification_section_1'
        ]
    )){
      this.getAllCategories();
      this.getAllClassification();
    }

  },

  methods: {
    getAllCategories() {
      let data = {
        page: 1,
        pageSize: 1000,
        lang: this.lang
      };
      let requestUrl = 'categories/index';
      let request = this.axios.post(requestUrl, data);
      request.then((response) => {
        this.categoriesData = response.data.data;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      });
    },
    getAllClassification() {
      let data = {
        page: 1,
        pageSize: 1000,
        lang: this.lang
      };
      let requestUrl = 'classifications/index'
      let request = this.axios.post(requestUrl, data);
      request.then((response) => {
        this.classificationData = response.data.data;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
      });
    },
    saveSectionData(data) {
      this.axios.post("visual-editor/save", data).then((response) => {
        this.getVisualEditor();
      }).catch((error) => {
        console.error(error);
      })
    },
    getVisualEditor() {
      this.axios.get("visual-editor/index").then((response) => {

        this.firstSectionData = (response.data.data.homePageSection1News) ? response.data.data.homePageSection1News : [];
        this.thirdSectionData = (response.data.data.homePageSection3News) ? response.data.data.homePageSection3News : {};
        this.classificationSection1Data =(response.data.data.homePageClassificationSection1) ? response.data.data.homePageClassificationSection1 : null;
        this.interviewData = response.data.data.homePageInterviewSectionData?response.data.data.homePageInterviewSectionData:[];
        this.homePageCategorySection1 =(response.data.data.homePageCategorySection1) ? response.data.data.homePageCategorySection1 : null;
        this.homePageCategorySection2 =(response.data.data.homePageCategorySection2) ? response.data.data.homePageCategorySection2 : null;
        this.homePageSection7 =(response.data.data.homePageSection7) ? response.data.data.homePageSection7 : null;
        this.homePageSection8 =(response.data.data.homePageSection8) ? response.data.data.homePageSection8 : null;
        this.loader.hide();
      }).catch((error) => {
        this.loader.hide();
        console.error(error);
      })

    }
  }
}
</script>
<style>
</style>

