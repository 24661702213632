<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i  v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_interview_section'])"
        @click="openSectionModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="interview-section">
      <div class="row pt-4" v-for="item in selectedInterview">
        <div class="col-md-6 content-section">
          <div class="text-right text-section">
            <h3>مقابلة</h3>
            <h5>{{ item.title }}</h5>
            <h6 class="font-weight-bold p-2">{{ item.excerpt }}</h6>
            <div class="information">
              <span>المصدر : {{ item.source.name }}</span> <span class="date">{{ item.date}}</span>
            </div>
            <a :href="item.url" class="read-more">قراءة المزيد</a>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <img class="image" :src="getUrl()+item.image" alt="no image for this news">
        </div>
      </div>
    </div>
    <interview-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_interview_section'])"
            :visible="sectionModalVisibility"
            :selectedInterview="modalSelectedList"
            @close="closeSectionModal"
            @selectedInterview="saveData"
    >
    </interview-section-modal>
  </fieldset>
</template>

<script>
    import InterviewSectionModal from "./InterviewSectionModal";

    export default {
        name: 'interview-section',
        components: {
            InterviewSectionModal,
        },
        watch: {
            selectedInterview: {
                handler: function (newValue) {
                    this.setModalData();
                },
                deep: true
            }
        },
        props: {
            selectedInterview: {
                type: Array,
            },
        },
        mounted() {},
        data() {

            return {
                modalSelectedList: [],
                sectionModalVisibility: false,
            }
        },

        methods: {
            getUrl() {
                return process.env.VUE_APP_SERVER_IMAGE_URL;
            },
            openSectionModal() {
                this.sectionModalVisibility = true;
            },
            closeSectionModal() {
                this.sectionModalVisibility = false;
            },
            setModalData(){
              this.modalSelectedList = this.selectedInterview;
                // this.selectedInterview.map((element, index) => {
                //     this.modalSelectedList.push({id: element.id, title: element.title});
                // })
            },
            saveData(value) {
                value.map((element, index) => {
                    element.sort_order =  index+1;
                });
                let data = {
                    'key': 'ar_home_page_interview_section',
                    'value': value,
                    'lang':this.lang,
                }
                this.$emit('saveData', data);
            }
        }

    }
</script>
<style>

  fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    margin: 5px 5px 1.5em 5px !important;
    padding: 0px !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
  }

  .visual-editor-btn {
    cursor: pointer;
  }

  .image {
    width: 100%;
    height: 100%;
  }
  .content-section{
    background-color: #f4bb57;
    color: #ffffff;
    padding: 10px;
  }
  .date{
    padding: 10px;
  }
  .information{
    margin-top: 10px;
  }
  .read-more{
    margin-top: 10px;
    color: #ffffff;
  }
  .interview-section{
    margin: 0 25px;
  }
</style>
