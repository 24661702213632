<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ title }}</h4>
          <div class="d-flex justify-content-start">
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :dataParam="dataParam"
            :method="'POST'"
            :row-key="'id'"
            :api-url="apiUrl">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <a v-if="scope.row.changes !== '[]'"
                   v-tooltip.top-center="scope.row.description"
                   class="btn-info btn-simple btn-link"
                   @click="openUpdatedItmesModal(scope.row.changes,scope.row.description)">
                  <i class="fa fa-eye"></i></a>
              </template>


            </el-table-column>


          </general-data-table>
        </div>

      </card>

    </div>
    <el-dialog
      :visible="updatedItemsModalVisibility"
      center
      @close="closeUpdatedItmesModal()"
    >
      <h4>{{ currentType }}</h4>
      <table v-if="currentType.includes('Updating')" class="table table-striped">
        <thead>
        <tr>
          <th class="font-weight-bold" style="width:25% !important;" scope="col">Property</th>
          <th class="font-weight-bold" scope="col">Old Value</th>
          <th class="font-weight-bold" scope="col">New Value</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in this.currentChanges">
          <td>{{ key }}</td>
          <td class="text-danger">
            <div v-if="item.old !== null&& !Array.isArray(item.old)">
              <div v-if="checkIfImage(item.old)"><img :src="getUrl()+item.old" width="80px" height="80px"></div>
              <div v-else-if="checkIfVideo(item.old)">
                <video controls width="150px" height="80px">
                  <source :src="getUrl()+item.old">
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="log-iframe-container" v-html="item.old" v-else-if="checkIfIframe"></div>
              <p v-else>{{ item.old }}</p>
            </div>
            <div v-if="item.old !== null&& Array.isArray(item.old)">
              <template class="m-1" v-for="(item, key) in item.old">
                <div v-if="checkIfImage(item)"><img :src="getUrl()+item" width="80px" height="80px"></div>
                <div v-else-if="checkIfVideo(item)">
                  <video controls width="150px" height="80px">
                    <source :src="getUrl()+item">
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="log-iframe-container" v-html="item" v-else-if="checkIfIframe"></div>
                <p v-else>{{ item }}</p>
              </template>
            </div>
          </td>
          <td class="text-success">
            <template v-if="item.new !== null&& !Array.isArray(item.new)">
              <div v-if="checkIfImage(item.new)"><img :src="getUrl()+item.new" width="80px" height="80px"></div>
              <div v-else-if="checkIfVideo(item.new)">
                <video controls width="150px" height="80px">
                  <source :src="getUrl()+item.new">
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="log-iframe-container" v-html="item.new" v-else-if="checkIfIframe"></div>
              <p v-else>
                {{ item.new }}
              </p>
            </template>
            <template v-if="item.new !== null&& Array.isArray(item.new)">
              <template class="m-1" v-for="(item, key) in item.new">

                <div v-if="checkIfImage(item)"><img :src="getUrl()+item" width="80px" height="80px"></div>
                <div v-else-if="checkIfVideo(item)">
                  <video controls width="150px" height="80px">
                    <source :src="getUrl()+item">
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="log-iframe-container" v-html="item" v-else-if="checkIfIframe"></div>
                <p v-else>{{ item }}</p>
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <table v-if="!currentType.includes('Updating')" class="table table-striped">
        <thead>
        <tr>
          <th class="font-weight-bold" scope="col">Property</th>
          <th class="font-weight-bold" scope="col">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in this.currentChanges">
          <td>{{ key }}</td>
          <td class="">{{ item }}</td>
        </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {Dialog, TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    NLPagination,
    [TableColumn.name]: TableColumn,
    GeneralDataTable,
    FgSelect,
    [Dialog.name]: Dialog,
  },
  props: {
    apiUrl: {
      required: true
    },
    title: {
      required: true
    },
    advanceSearchFilter: {
      required: false
    },
    dataParam: {
      required: true
    },
  },

  data() {

    return {
      tableColumns: [],
      fixedColumn: null,
      updatedItemsModalVisibility: false,
      currentChanges: '',
      currentType: '',
    }

  },

  created() {
    this.responsiveViewPort();
  },

  methods: {

    openUpdatedItmesModal(changes, type) {
      this.updatedItemsModalVisibility = true;
      this.currentChanges = JSON.parse(changes);
      this.currentType = type;
    },

    closeUpdatedItmesModal() {
      this.updatedItemsModalVisibility = false;
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    checkIfImage(item) {
      if (typeof item !== 'string') {
        return false;
      }
      return (item.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) != null);
    },
    checkIfVideo(item) {
      if (typeof item !== 'string') {
        return false;
      }
      return (item.match(/\.(mp4|mov|wmv|avi|flv|webm)$/) != null);
    },
    checkIfIframe(item){
      if (typeof item !== 'string') {
        return false;
      }
      return item.includes('<iframe');
    },
    responsiveViewPort() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.fixedColumn = null;
        this.tableColumns = [
          {label: 'Description', value: 'description', minWidth: '200', align: 'center'},
          {label: 'Causer First Name', value: 'causer.first_name', minWidth: '200', align: 'center'},
          {label: 'Causer Last Name', value: 'causer.last_name', minWidth: '200', align: 'center'},
        ];
      } else {
        this.fixedColumn = 'right';
        this.tableColumns = [
          {label: 'Description', value: 'description', minWidth: '200', align: 'center'},
          {label: 'Causer First Name', value: 'causer.first_name', minWidth: '200', align: 'center'},
          {label: 'Causer Last Name', value: 'causer.last_name', minWidth: '200', align: 'center'},
          {label: 'Causer Email', value: 'causer.email', minWidth: '200', align: 'center'},
          {label: 'Date', value: 'created_at', minWidth: '200', align: 'center'},
        ];
      }
    }
  },

}
</script>
<style>
.log-iframe-container iframe{
  max-width: 150px !important;

  max-height: 150px!important;
}
</style>
